import React from 'react'
import { useEffect, useState } from 'react'
import * as d3 from 'd3'

const KEY = process.env.vc

const uri2 =
  `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/New%20York%20City%2CNY?unitGroup=us&key=2CMWNL8SGERFAH7UPYQSQYCZM&contentType=json`

  const uri= 
  `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/fairfax%2Cva?unitGroup=us&key=2CMWNL8SGERFAH7UPYQSQYCZM&contentType=json`

function WeatherVC({location}) {
  // const { eventtime } = useParams()
  const [data, setData] = useState({})
  const [event, setEvent] = useState({})
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const getStatus = async () => {
      const response = await fetch(uri, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'Authorization':'Bearer '+this.token
        },
      })

      const responseJson = await response.json()
      console.log('json', responseJson)
      setData(responseJson)
      setLoading(false)
    }
    getStatus()
  }, [])

  if (isLoading) {
    return <div>loading</div>
  } else {
    return (
      <div>
        <h2 className='text-xl'>{data.resolvedAddress}</h2>
        <h2 className='text'>Currently: {data.currentConditions.conditions} {data.currentConditions.feelslike} <img className='text-xs inline' src={ require(`../../public/static/weather-icons/${data.currentConditions.icon}.png`) } alt={data.currentConditions.icon} width="25" height="25" /></h2>
        <h2 className='text-xs'>{data.description}</h2>
        {console.log(`../static/weather-icons/${data.currentConditions.icon}.png`)}
        <ul>
        {data.days.map((item, key) => 
          <li key={key} className='text-xs'>{item.datetime}  <img className='inline text-xs' src={ require(`../../public/static/weather-icons/${item.icon}.png`) } alt={item.icon} width="25" height="25"/> {item.description} High: {item.tempmax} Low: {item.tempmin}</li>
        )}
        </ul>
      </div>
    )
  }
}

export default WeatherVC
