import React from 'react'
import * as d3 from 'd3'
import wmataimage from './Capture.PNG'

const Graphic = () => {
  const svgRef = React.useRef(null)
  const dimensions = {
    width: 600,
    height: 400,
    margin: { top: 30, right: 30, bottom: 30, left: 60 },
  }

  console.log('indie')

  const { width, height, margin } = dimensions
  const svgWidth = width + margin.left + margin.right
  const svgHeight = height + margin.top + margin.bottom

  React.useEffect(() => {
    const svgEl = d3.select(svgRef.current)
    const svg = svgEl
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    svg
      .append('image')
      .attr('xlink:href', wmataimage)
    //   .attr('xlink:href', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0u8Q5eULmw4XN3KMnkgjcCjDj9fUVVvb9QA&usqp=CAU')
      .attr('width', 600)
      .attr('height', 400)

    svg
      .append('circle')
      .attr('id', 'circleBasicTooltip')
      .attr('cx', 542)
      .attr('cy', 253)
      .attr('r', 10)
      .attr('fill', 'red')
      .append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .text("I'm a circle!")

    svg.on("mousemove", function(event) {
        // console.log("x - using invert", d3.pointer(this)[0]);
        // console.log("y- using invert", d3.pointer(this)[1]);
        console.log(d3.pointer(event))
      });
    //   var textout = 'hello'
  }, [])
  return <svg ref={svgRef} width={svgWidth} height={svgHeight} />
}
export default Graphic
