import React from 'react'
import * as d3 from 'd3'
import { initializeApp } from 'firebase/app'
import {
  collection,
  doc,
  getDocs,
  setDoc,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore'

//import firebase from 'firebase/app'
// import 'firebase/firestore';

function Donut() {
  const svgRef = React.useRef(null)
  const dims = { height: 300, width: 300, radius: 150 }
  const cent = { x: dims.width / 2 + 5, y: dims.height / 2 + 5 }

  // TODO: Replace the following with your app's Firebase project configuration
  const firebaseConfig = {
    apiKey: 'AIzaSyBy2TcjUO-B8MBXwTBrZ_6weimu8xG0378',
    authDomain: 'udemy-d3-p1-34e08.firebaseapp.com',
    projectId: 'udemy-d3-p1-34e08',
    storageBucket: 'udemy-d3-p1-34e08.appspot.com',
    messagingSenderId: '779875757706',
    appId: '1:779875757706:web:d0cec4d446dae2faf6c773',
  }

  const app = initializeApp(firebaseConfig)

  // Initialize Cloud Storage and get a reference to the service
  //const db = getFirestore(app);
  const db = getFirestore(app)

  React.useEffect(() => {
    const svg = d3
      .select('.root')
      .append('svg')
      .attr('width', dims.width + 150)
      .attr('height', dims.height + 150)

    const graph = svg
      .append('g')
      .attr('transform', `translate(${cent.x},${cent.y})`)

    const pie = d3
      .pie()
      .sort(null)
      .value((d) => d.cost)

    // const angles = pie([
    //     {name:'rent', cost:500},
    //     {name:'bills',cost:300},
    //     {name:'gaming',cost:200}
    // ])

    const arcPath = d3
      .arc()
      .outerRadius(dims.radius)
      .innerRadius(dims.radius / 2)

    const color = d3.scaleOrdinal(d3['schemeSet3'])

    const legendGroup = svg
      .append('g')
      .attr('transform', `translate(${dims.width + 40},10)`)

    // const legend = d3.legendColor().shape("circle").shapePadding(15).scale(color)
    // var tip = d3.tip().attr('class', 'd3-tip').html((EVENT,d)=> d );
    // const tip = d3
    //   .tip()
    //   .attr("class", "d3-tip card") // We add the d3-tip class instead of the tip class
    //   .html((event, d) => {
    //     // It's (event, d) instead of just (d) in v6
    //     let content = `<div class="name">${d.data.name}</div>`
    //     content += `<div class="cost">$${d.data.cost}</div>`
    //     content += `<div class="delete">Click slice to delete</div>`
    //     return content
    //   })

    // graph.call(tip)

    const update = (data) => {
      //update color sclae domain
      color.domain(data.map((d) => d.name))

      // legendGroup.call(legend)
      // legendGroup.selectAll("text").attr("fill", "white")

      //join enhanced (pie ) data to path elements
      const paths = graph.selectAll('path').data(pie(data))

      //exit
      paths
        .exit()
        .transition()
        .duration(750)
        .attrTween('d', arcTweenExit)
        .remove()

      //upate
      paths
        .attr('d', arcPath)
        .transition()
        .duration(750)
        .attrTween('d', arcTweenUpdate)

      //enter
      paths
        .enter()
        .append('path')
        .attr('class', 'arc')
        // .attr('d', arcPath)
        .attr('stroke', '#fff')
        .attr('strke-width', 1)
        .attr('fill', (d) => color(d.data.name))
        .each(function (d) {
          this._current = d
        })
        .transition()
        .duration(750)
        .attrTween('d', arcTweenEnter)

      //add events
      // graph
      //   .selectAll('path')
      //   .on('mouseover', (event, d) => {
      //     tip.show(event, d)
      //     handleMouseOver(event, d)
      //   })
      //   .on('mouseout', (event, d) => {
      //     tip.hide()
      //     handleMouseOut(event, d)
      //   })
      //   .on('click', handleClick)
    }

    var data = []

    // db.collection('expenses').onSnapshot((res) => {
    //   res.docChanges().forEach((change) => {
      const querySnapshot =  getDocs(collection(db, "expenses"));
querySnapshot.forEach((change) => {
  console.log(change)
        const doc = { ...change.doc.data(), id: change.doc.id }
        switch (change.type) {
          case 'added':
            data.push(doc)
            break
          case 'modified':
            const index = data.findIndex((item) => item.id == doc.id)
            data[index] = doc
            console.log(`doc mod: ${doc}`)
            break
          case 'removed':
            data = data.filter((item) => item.id !== doc.id)
            break
          default:
            break
        }
      })
      update(data)
    // })

    const arcTweenEnter = (d) => {
      var i = d3.interpolate(d.endAngle, d.startAngle)

      return function (t) {
        d.startAngle = i(t)
        return arcPath(d)
      }
    }

    const arcTweenExit = (d) => {
      var i = d3.interpolate(d.startAngle, d.endAngle)

      return function (t) {
        d.startAngle = i(t)
        return arcPath(d)
      }
    }

    //use function keyword (so we can this keyword)
    function arcTweenUpdate(d) {
      //interpolate two objects
      var i = d3.interpolate(this._current, d)
      //update the current prop with new updated data
      this._current = d

      return function (t) {
        return arcPath(i(t))
      }
    }

    const handleMouseOver = (event, d) => {
      //console.log(event.currentTarget);
      d3.select(event.currentTarget)
        .transition('changeSliceFill')
        .duration(300)
        .attr('fill', '#fff')
    }
    const handleMouseOut = (event, d) => {
      //console.log(event.currentTarget);
      d3.select(event.currentTarget)
        .transition('changeSliceFill')
        .duration(300)
        .attr('fill', color(d.data.name))
    }

    const handleClick = (event, d) => {
      const id = d.data.id
      db.collection('expenses').doc(id).delete()
    }
  }, [])
  return <graph ref={svgRef} width={dims.width} height={dims.height} />
}

export default Donut
