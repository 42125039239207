import React from 'react'
// import Heatmap from './Heatmap';

// import CalendarCopy from './Calendar copy';
// import Calendar2 from './Calendar2';

import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import 'react-calendar-heatmap/dist/styles.css';
//import './react-calendar-heatmap.css';

const data = [
  {
    date: "2021-01-01",
    contributions: 10,
    details: {
      visits: 16,
      submissions: 5,
      notebooks: 1,
      discussions: 4
    }
  },
  {
    date: "2021-01-02",
    contributions: 10,
    details: {
      visits: 16,
      submissions: 5,
      notebooks: 1,
      discussions: 4
    }
  },
  {
    date: "2021-01-05",
    contributions: 5,
    details: {
      visits: 16,
      submissions: 5,
      notebooks: 1,
      discussions: 4
    }
  },
  {
    date: "2021-02-05",
    contributions: 3,
    details: {
      visits: 16,
      submissions: 5,
      notebooks: 1,
      discussions: 4
    }
  }
];

function Calendar() {


  return (
<div className="container ml-4 mr-4 mx-auto px-4">
    <CalendarHeatmap
    startDate={new Date('2022-01-01')}
    endDate={new Date('2022-07-01')}
    values={[
      { date: '2022-04-01', count: 12 },
      { date: '2022-05-01', count: 12 },
      { date: '2022-05-22', count: 122 },
      { date: '2022-06-30', count: 38 },
      { date: '2022-07-20', count: 38 },

    ]}


    classForValue={value => {
      if (!value) {
        return 'color-empty';
      }
      return `color-github-${value.count}`;
    }}
    tooltipDataAttrs={value => {
      return {
        'data-tip': `${value.date} has count: ${
          value.count
        }`,
      };
    }}
    showWeekdayLabels={true}
    onClick={value => alert(`Clicked on value with count: ${value.count}`)}
  
  />
  <ReactTooltip />
  </div>

  )
}

export default Calendar
