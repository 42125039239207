import React from 'react'
import Graphic from './wmata/Graphic'

function WmataPage() {
  return (
    <div>
        <Graphic />
        <Graphic />
      
    </div>
  )
}

export default WmataPage
