import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Barchartview from './barchart/Barchartview'
import Gridview from './grid/Gridview'
import Graphic from './wmata/Graphic'
import Example from './tailwind/Main'
import "./index.css";
import Basic from './Basic'
import WmataPage from './WmataPage'
//import CalendarHeat from './calendar/CalendarHeat'
import Calendar from './calendar/Calendar'
import Heatmap from './calendar/WongHeatmapOld'
import Donut from './donut/Donut'
import WeatherVC from './vc/WeatherVC'
//import Pano3d from './pano/Pano3d'
import Pano from './pano/Pano'



function App() {
  return (
    <Router>
          {/* <div className='flex lex-col justify-between h-screen'> */}
          {/* navbar */}
                {/* <Example/> */}
                {/* <main className='container mx-auto px-3 px-12'> */}

      
        <Routes>

          <Route path='/' element={<Graphic />} />
          {/* <Route path='/' element={<Donut />} /> */}
          <Route path='/barchart' element={<barchartview />} />
          <Route path='/grid' element={<Gridview />} />
          <Route path='/wmata' element={<WmataPage />} />
          <Route path='/main' element={<Example />} />
          <Route path='/basic' element={<Basic />} />
          <Route path='/donut' element={<Donut />} />
          <Route path='/calendar' element={<Calendar />} />
          <Route path='/weathervc' element={<WeatherVC />} />
          <Route path='/pano' element={<Pano test={"/static/assets/pano3.jpg"}/>} />
        </Routes>
        {/* <header className='App-header'></header> */}
        {/* </main>  */}
    {/* </div> */}


    </Router>

  )
}

export default App
