import React from 'react'
import Legend from "./Legend";
import MultilineChart from "./MultilineChart";
import schc from "./SCHC.json";
import vcit from "./VCIT.json";
import portfolio from "./portfolio.json";
 
const portfolioData = { name: "Portfolio", color: "#ffffff", items: portfolio };
const schcData = { name: "SCHC", color: "#d53e4f", items: schc };
const vcitData = { name: "VCIT", color: "#5e4fa2", items: vcit };
const dimensions = {
  width: 600,
  height: 300,
  margin: { top: 30, right: 30, bottom: 30, left: 60 }
};

export function Gridview() {
    return (
      <div className="App">
        <MultilineChart
          data={[portfolioData, schcData, vcitData]}
          dimensions={dimensions}
        />
      </div>
    );
  }
  export default Gridview;